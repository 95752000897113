<template>
  <CContainer>
    <template v-if="changetemplate==0">
          <CRow>
              <CCol col="12">
                <CCard>
                    <CCardHeader>
                        <strong>Recarga Pse</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow class="justify-content-center">
                            <CCol col="12">
                              <div class="alert alert-warning" role="alert">
                                 <h5>En el momento no puedes realizar recargas por Pse, porque tus datos de facturacion no estan configurados por favor contacta a tu asesor comercial para que configure esta opcion</h5>
                              </div>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
              </CCol>
            </CRow>
      </template>
      <template v-if="changetemplate==1">
          <CRow>
              <CCol col="12">
                <CCard>
                    <CCardHeader>
                        <strong>Recarga Pse</strong>
                    </CCardHeader>
                    <CCardBody>
                      <CRow class="justify-content-center">
                          <CCol col="6">
                            <img :src="imagenpse" alt="">
                          </CCol>
                      </CRow>
                        <CRow class="justify-content-center">
                            <CCol col="6">
                                <label for="">Ingresa el Valor a Recargar</label>
                                <input class="form-control" minlength="1" id="uid-ip8fkjcnasjl-recarga4" v-model.lazy="form.saldo_recargar" v-money="money" @keyup="validaCantidadMaxima" autocomplete="off" />
                                <span>Lo minimo que puede Recargar es : <strong>{{creditosConversorMoneda1(parametros_config.minimo_recarga_pse)}}</strong> </span>
                            </CCol>
                        </CRow>
                        <br>
                        <CRow class="justify-content-center">
                            <CCol col="6">
                                <CSelect
                                    label="Seleccionar Producto a Recargar"
                                    placeholder="Producto"
                                    :options="opcionProductosPse"
                                    required
                                    was-validated
                                    :value.sync="producto_select"
                                    >
                                </CSelect>  
                            </CCol>
                        </CRow>
                        <br>
                        <CRow class="justify-content-center">
                            <CCol col="6">
                                <CButton  color="success" :disabled="validaCheckRecarga" block @click="recargarpsedetalle"> Aceptar </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
              </CCol>
            </CRow>
      </template>
      <template v-if="changetemplate==2">
          <CRow>
              <CCol col="12">
                <CCard>
                    <CCardHeader>
                        <strong>Detalle de la Recarga</strong>
                    </CCardHeader>
                    <CCardBody>
                      <CRow class="justify-content-center">
                          <CCol col="6">
                            <img :src="imagenpse" alt="">
                          </CCol>
                      </CRow>
                      <CRow class="justify-content-center">
                        <CCol col="6">
                          <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td scope="col">Valor a Recargar</td>
                                  <td scope="col">{{creditosConversorMoneda2(detallerecarga.sin_iva)}}</td>
                                </tr>
                                <tr>
                                  <td scope="col">Iva {{parametros_config.iva_recarga_pse}} %</td>
                                  <td scope="col">{{creditosConversorMoneda2(detallerecarga.iva)}}</td>
                                </tr>
                                <tr>
                                  <td scope="col">Total a Pagar</td>
                                  <td scope="col">{{creditosConversorMoneda2(detallerecarga.con_iva)}}</td>
                                </tr>
                              </tbody>
                            </table>
                            <br>
                            <CRow class="justify-content-between">
                              <CCol col="3">
                                <CButton  color="danger" class="mr-auto" @click="cancelarrecarga"> Cancelar </CButton>
                              </CCol>
                               <CCol col="3">
                                <CButton  color="success" @click="recargarpsepasarela"> Ir a Pagar </CButton>
                              </CCol>
                            </CRow>
                        </CCol>
                      </CRow>
                    </CCardBody>
                </CCard>
              </CCol>
            </CRow>
      </template>
      <template v-if="changetemplate==3">
          <CRow>
              <CCol col="12">
                <CCard>
                    <CCardHeader>
                        <strong>Recarga Pse</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow class="justify-content-center">
                            <CCol col="12">
                                <div class="alert alert-warning" role="alert">
                                   <p>En este momento su <strong># {{pagopendiente.Id_Pago}}</strong> presenta un proceso de pago cuya transaccion se encuentra <strong>PENDIENTE</strong> de recibir confirmacion por parte de su entidad financiera,
                                      por favor espere unos minutos y vuelva a consultar mas tarde para verificar si su pago fue confirmado de forma exitosa.
                                      Si desea mayor informacion sobre el estado actual de su operacion puede comunicarse a nuestras lineas de atencion al cliente al telefono  <strong>57 310 5 39 11 11</strong>
                                      o enviar sus inquietudes al correo soporte@itcloudcolombia.com y pregunte por el estado de la transaccion <strong>{{pagopendiente.codigo_transaccion}}</strong></p>
                                </div>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
              </CCol>
            </CRow>
      </template>
         <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
        </div>
  </CContainer>
</template>

<script>
import {VMoney} from 'v-money';
export default {
  name: 'RecargaPse',
  data () {
    return {
        mostrarNotificacion: 0,
        mensaje_success:'Datos Guardado Correctamente.',
        mostrarNotificacionAlerta: 0,
        mensaje_warning:'Debe Completar Los Datos Vacio',
        mostrarNotificacionError:0,
        changetemplate: 11,
        form:{
            saldo_recargar:0.00,
        },
        validaCheckRecarga:true,
        parametros_config:{
            minimo_recarga_pse:0,
            max_creditos_recarga:0,
            iva_recarga_pse:0,
        },
        detallerecarga:null,
        pagopendiente:null,
        urlpagar:'',
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$',
          suffix: '',
          precision: 0,
          masked: false,
        },
        opcionProductosPse:[],
        producto_select: 2
    }
  },
  directives: {money: VMoney},
  methods: {
    creditosConversorMoneda(value){
        const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
        })
        return (formatterDolar.format(value)).replace('$','');
    },
    creditosConversorMoneda1(value){
        const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
        })
        return (formatterDolar.format(value)).replace('.00','');
    },
    creditosConversorMoneda2(value){
        const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
        })
        return formatterDolar.format(value);
    },
    validaCantidadMaxima(event){
      let texto = event.target.value;
      let maximo = this.creditosConversorMoneda(this.parametros_config.maximo_recarga_pse)
      maximo = maximo.replaceAll(',','');
      maximo = maximo.replaceAll('.',',');
      texto = texto.replaceAll('.','');
      texto = texto.replaceAll('$','');
      if (parseInt(texto) > parseInt(maximo)) {
        event.target.value = '0'
      }
      let minimo = this.creditosConversorMoneda(this.parametros_config.minimo_recarga_pse)
      minimo = minimo.replaceAll(',','');
      minimo = minimo.replaceAll('.',',');
      if (parseInt(texto) < parseInt(minimo)) {
          this.validaCheckRecarga = true;
      }else{
          this.validaCheckRecarga = false;
      }
    },
    validaCantidadMaximaForm(){
      let texto = document.getElementById("uid-ip8fkjcnasjl-recarga4").value;
      let maximo = this.creditosConversorMoneda(this.parametros_config.maximo_recarga_pse)
      maximo = maximo.replaceAll(',','');
      maximo = maximo.replaceAll('.',',');
      texto = texto.replaceAll('.','');
      texto = texto.replaceAll('$','');
      if (parseInt(texto) > parseInt(maximo)) {
        document.getElementById("uid-ip8fkjcnasjl-recarga4").value  = 0;
      }
      let minimo = this.creditosConversorMoneda(this.parametros_config.minimo_recarga_pse)
      minimo = minimo.replaceAll(',','');
      minimo = minimo.replaceAll('.',',');
      let retorna = true;
      if (parseInt(texto) < parseInt(minimo)) {
          this.validaCheckRecarga = true;
          retorna  = false;
      }else{
          this.validaCheckRecarga = false;
          retorna  = true;
      }
      return retorna;
    },
    getParametrosConfiguracion(){
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.post('/sms/parametros/configuracion',{ 
          parametros:['minimo_recarga_pse','maximo_recarga_pse','iva_recarga_pse']
        }).then(response => {
            this.parametros_config = response.data;
        }).catch((e)=>{
          this.mostrarNotificacionError ++;
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));

        //consulta los productos pse
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/productos/pse').then(response => {
            let data = response.data;
            for (const iterator of data) {
              this.opcionProductosPse.push({label:iterator.nombre,value:iterator.id_producto});
            }
        }).catch((e)=>{
          this.mostrarNotificacionError ++;
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    cancelarrecarga(){
        try {
          this.form.saldo_recargar=0.00;
          this.changetemplate=1;
          document.getElementById("uid-ip8fkjcnasjl-recarga4").value  = 0;
        } catch (error) {
          
        }
    },
    recargarpsedetalle(){
       let validasaldo = this.validaCantidadMaximaForm();
       if (validasaldo == true) {
          this.$store.dispatch('auth/loading_http','visible');
          this.$http.post('/sms/calcula/pse',{ 
            saldo_recargar: this.form.saldo_recargar,
            id_producto:this.producto_select,
          }).then(response => {
              let data = response.data;
              if (data == 'Error Minimo') {
                this.mostrarNotificacionAlerta ++
                this.mensaje_warning='Error al Recargar, Es menor a la cantidad minima para recargar'
              }else if (data == 'Error Maximo') {
                this.mostrarNotificacionAlerta ++
                this.mensaje_warning='Error al Recargar, Supera la Cantidad Maxima para recargar'
              }else{
                this.detallerecarga =  response.data;
                this.changetemplate=2;
              }
          }).catch((e)=>{
            this.mostrarNotificacionError ++;
          }).finally(() =>this.$store.dispatch('auth/loading_http','hidden')); 
       }else{
          this.mostrarNotificacionAlerta ++
          this.mensaje_warning='Debe Completar Los Datos Vacio'
       }
    },
    recargarpsepasarela(evt){
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post('/sms/recarga/pse',{
        saldo_recargar: this.form.saldo_recargar,
        id_producto:this.producto_select,
      }).then(response => {
        let data = response.data;
        if (data == "Error"){
          this.mostrarNotificacionError ++;
        }else if (data['respuesta'] == "NO Datos") {
          this.changetemplate = 0;
        }else if (data['respuesta'] == "Pago Pendiente"){
          this.pagopendiente = data['pago'];
          this.getParametrosConfiguracion();
          this.changetemplate = 3;
        }else if(data == 'NO'){
          this.goBack();
        }else {
           this.mostrarNotificacion++
           this.mensaje_success='Datos Guardado Correctamente.'
           this.$store.dispatch('auth/getValidaPse');
           this.validarecarga();
           this.urlpagar = response.data;
           window.open(this.urlpagar, '_blank');
        }
        this.cancelarrecarga();
      }).catch((e)=>{
        this.mostrarNotificacionError ++;
        console.log(e);
      }).finally(() =>{
        setTimeout(() => {
          this.$store.dispatch('auth/loading_http','hidden');
          evt.target.disabled = false;
        }, 3000);
      }); 
    },
    validarecarga(){
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/pse').then(response => {
            let data = response.data;
            if (data['respuesta'] == "OK") {
              this.changetemplate = 1;
              this.getParametrosConfiguracion();
            }else if (data['respuesta'] == "NO Datos") {
              this.changetemplate = 0;
            }else if (data['respuesta'] == "Pago Pendiente"){
              this.pagopendiente = data['pago'];
              this.getParametrosConfiguracion();
              this.changetemplate = 3;
            }else{
              this.goBack();
            }
        }).catch((e)=>{
          this.mostrarNotificacionError ++;
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/dashboard'})
    },
  },
  mounted () {
     setTimeout(() => {
        this.validarecarga();
      }, 100);
  },
  computed: {
    validaCheckAsignarCreditos(){
      return this.autorizacion_check  == false ? true : false;
    },
    imagenpse(){
      return `${this.$apiAdress}/dist/img/logo_pse.png`
    },
  }
}
</script>
